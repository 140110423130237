<template>
  <div class="agent-card">
    <div class="agent-fans">
      <div class="agent-fans-hero">
        <h5>
          粉丝:
          <p>通過您的邀請鏈接登入iPastBook的用戶</p>
        </h5>
        <button class="btn primary" @click="FBInvite(user.uid)">去邀请</button>
      </div>
      <div class="agent-fans-content">
        <fans-item v-for="(item, index) in fans" :key="index" :item="item">
          <div>
            <span v-if="!item.ordered">未下单</span>
            <div class="text-right" v-else>
              <b class="text-primary">已下单</b>
              <p>{{item.orderAt}}</p>
            </div>
          </div>
        </fans-item>
        <div class="text-center content__action mt-1">
          <span class="text-small text-gray" v-if="page > total && fans.length">沒有更多了</span>
          <div v-else>
            <div class="text-center" v-if="fetching">
              <div class="loading-icon"></div>
              <p class="mt-1 text-mini text-gray">加载中...</p>
            </div>
            <button class="btn default small" @click="fetchFans" v-else-if="!fetching && fans.length">加载更多</button>
            <template v-else>
              <span>還沒有粉絲下單呢</span>
              <p class="mt-1">
                <button class="btn primary" @click="FBInvite(user.uid)">邀請粉絲</button>
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import AgentFansItem from '../../components/layout/AgentFansItem'
import FBShare from 'src/mixins/FBShare'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'Agent-Fans',
  mixins: [FBShare],
  components: { FansItem: AgentFansItem },
  data: () => ({
    page: 1,
    total: 1,
    fetching: false,
    fans: []
  }),
  computed: {
    ...mapGetters(['user'])
  },
  created () {
    this.fetchFans()
  },
  methods: {
    fetchFans () {
      if (this.fetching) return
      this.fetching = true
      if (this.page > this.total) return
      api.fetchAgentFansList(this.page).then(data => {
        this.fetching = false
        if (data.errors) return
        this.page++
        this.total = data.pages
        data.data = data.data.map(item => {
          item.orderAt = moment(item.latestOrderedAt).format('YYYY-MM-DD')
          return item
        })
        this.fans = this.fans.concat(data.data)
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
@import '../../styles/variables';
.agent-fans {
  &-hero {
    display: flex;
    background-color: #fef3f3;
    padding: 16px;
    color: $el-color-primary;
    margin: 0 -1rem;
    h5 {
      flex: 1;
      font-weight: 500;
      font-size: 18px;
      text-align: left;
      margin: 0;
      p {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  &-content {
    margin-top: 1rem;
  }
}
</style>
